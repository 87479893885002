<template>
  <div
    class="tc-answer-content"
    v-loading="loading"
    element-loading-text="加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(183,208,208,0.8)"
  >
    <transition name="el-zoom-in-top">
      <div class="answer-content-layout" v-if="brief">
        <div class="title">
          <p>{{ measureInfo.measure_title }}</p>
          <el-progress :percentage="Progress"></el-progress>
        </div>
        <div class="answer-main" v-if="questionsList.length > 0">
          <!-- 题目标题 -->
          <div class="main-title" v-html="questionsList[index].question"></div>
          <!-- 题目选项 -->
          <div class="centen">
            <table @change="radio">
              <tr
                v-for="(item, key) in questionsList[index].options"
                :key="key"
              >
                <td>
                  <input type="radio" :id="key" :value="key" v-model="picked" />
                  <label :for="key">
                    <i class="fa fa-square-o"></i>
                    <!-- <span>{{item}}</span> -->
                    <span v-html="item"></span>
                  </label>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="footer">
          <div class="btn">
            <el-button @click="last">上一题</el-button>
          </div>
          <div class="sum">
            <span>
              共
              <span class="sum_info" v-if="measureInfo.questions">{{
                measureInfo.questions.length
              }}</span
              >&nbsp;题&nbsp;
            </span>
            <br />
            <span>
              当前第
              <span class="sum_info">{{ index + 1 }}</span
              >&nbsp;题
            </span>
          </div>
          <div class="btn">
            <el-button
              @click="next"
              v-if="
                answer.length < questionsList.length ||
                index < questionsList.length - 1
              "
              >下一题</el-button
            >
            <!--  -->
            <el-button
              @click="submit()"
              v-if="
                answer.length >= questionsList.length &&
                index >= questionsList.length - 1
              "
              :disabled="isSubmit"
              >提 交</el-button
            >
          </div>
        </div>
        <!-- ------------------测试模态框 ------------------------------------>
        <el-dialog
          :close-on-click-modal="false"
          title="测试专用"
          :visible.sync="testBox"
          width="50%"
          center
        >
          <el-form ref="testform" :model="testform" label-width="80px">
            <el-form-item label="全选">
              <el-radio-group v-model="testform.resource">
                <el-radio label="A">全选 A</el-radio>
                <el-radio label="B">全选 B</el-radio>
                <el-radio label="C">全选 C</el-radio>
                <el-radio label="D">全选 D</el-radio>
                <el-radio label="E">全选 E</el-radio>
                <el-radio label="F">全选 F</el-radio>
                <el-radio label="G">全选 G</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="testBox = false">取 消</el-button>
            <el-button type="primary" @click="setTest">提 交</el-button>
          </span>
        </el-dialog>
        <!-- ----------------------------------------------------------------->
      </div>
    </transition>
    <!-- 量表介绍 -->
    <div class="answer-content-layout" v-if="!brief">
      <div class="title">
        <p>{{ measureInfo.measure_title }}</p>
      </div>
      <div class="describe">
        <p v-html="measureInfo.describe"></p>
      </div>
      <div class="msg">
        <p v-html="measureInfo.msg"></p>
      </div>
      <el-row type="flex" justify="center" style="margin-top: 30px">
        <el-button type="primary" plain @click="hideBox">开始答题</el-button>
      </el-row>
    </div>
    <!-- 偷偷加载 -->
    <div class="toto" v-show="false">
      <div v-for="(r, k) in questionsList" :key="k">
        <div v-html="r.question"></div>
        <div v-for="(r2, k2) in r.options" :key="k2">
          <div v-html="r2"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { submit } from "@/api/answer.js";
import { getMeasureInfobyid } from "@/api/evaluate.js";
export default {
  inject: ["reload"],
  data() {
    return {
      isSubmit: false, //提交按钮
      loading: true,
      testform: { resource: "" },
      testBox: false,
      ischeck: "",
      resource: "",
      picked: "",
      measureInfo: "",
      questionsList: [],
      index: 0,
      answer: [],
      type: 0,
      measureId: "",
      uid: "",
      Progress: 0,
      brief: false,
      continueMes: false, // 连续填写问卷开关
      recordTiao: [],
    };
  },
  created() {
    this.loadanswer();
  },
  methods: {
    // ----------------------测试专用------------------------------
    showtestBox() {
      this.testBox = true;
    },
    hideBox() {
      this.brief = true;
    },
    setTest() {
      // 数据处理
      this.questionsList.forEach((v, k) => {
        let obj = { id: v.id, answer: this.testform.resource };
        this.answer.push(obj);
      });
      this.submit();
    },
    // ----------------------------------------------------
    // 单选监控
    radio(val) {
      // //console.log(val.target.parentNode.parentNode);
      // // 字体图标的变换
      var p = document.querySelectorAll(".fa");
      // // 1-还原所有
      p.forEach((v) => {
        v.classList.remove("fa-check-square");
        v.classList.add("fa-square-o");
      });
      // // 2-判断类名，设置类名
      var className =
        val.target.parentNode.children[1].children[0].getAttribute("class");
      if (className == "fa fa-square-o") {
        val.target.parentNode.children[1].children[0].classList.remove(
          "fa-square-o"
        );
        val.target.parentNode.children[1].children[0].classList.add(
          "fa-check-square"
        );
      }
      // // tr 背景的切换
      // // 1-清除所有 tr背景
      var tr = document.querySelectorAll("tr");
      tr.forEach((v) => {
        v.classList.remove("active");
      });
      // // 2-设置当前tr背景
      val.target.parentNode.parentNode.classList.add("active");
      setTimeout(() => {
        this.next();
      }, 250);
    },
    async loadanswer() {
      let id = this.$route.query.id;
      this.measureId = this.$route.query.id;
      let res = await getMeasureInfobyid(id);
      let r = { data: {} };
      r.data = res;
      // //console.log("r", r.data);
      if (r.data.code == 400400) {
        this.$message({
          message: r.data.msg,
          type: "warning",
          center: true,
          showClose: true,
          duration: 1500,
        });
        this.$router.go(-1); //返回上一层
        return;
      }
      this.measureInfo = r.data.data;
      //  题目数据

      // r.data.data.questions.forEach((v,k)=>{
      //   var thishtml = [];
      //   v.options.forEach((vs,ks)=>{
      //     //console.log(vs);
      //       vs = vs.replace("<imgdiv>", "<img src='/ruiwen/ruiwen/");
      //       vs = vs.replace("</imgdiv>", ".jpg'>");
      //       thishtml[ks] = vs;
      //   })
      //   v = thishtml;
      //   console.log(thishtml);
      // })

      this.questionsList = r.data.data.questions;
      // console.log(this.questionsList);

      this.loading = false;
      // //console.log(this.measureInfo);
      this.type = r.data.show_result;
      // 获取缓存,续题
      this.uid = localStorage.getItem("user_id");
      if (
        JSON.parse(
          localStorage.getItem(`measureId${this.uid}-${this.measureId}`)
        )
      ) {
        this.brief = true;
        this.$confirm("是否从上一次答题处开始?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.answer = JSON.parse(
              localStorage.getItem(`measureId${this.uid}-${this.measureId}`)
            );
            this.recordTiao =
              JSON.parse(
                localStorage.getItem(`recordTiao${this.uid}-${this.measureId}`)
              ) ?? [];
            if (this.answer.length >= this.questionsList.length) {
              this.index = this.answer.length - 1;
            } else {
              this.index = this.answer.length;
            }
            // 计算进度条
            this.Progress = Math.ceil(
              (this.answer.length / this.questionsList.length) * 100
            );
            // 如果是最后一题,则渲染并 提示提交
            if (this.answer.length == this.questionsList.length) {
              //模拟已选择
              var arr = [
                "A",
                "B",
                "C",
                "D",
                "E",
                "F",
                "G",
                "H",
                "I",
                "J",
                "K",
                "L",
                "M",
                "N",
                "O",
                "P",
                "Q",
                "R",
                "S",
                "T",
                "U",
                "V",
                "W",
                "X",
                "Y",
                "Z",
              ];
              var answers = this.answer[this.answer.length - 1].answer;
              var index = arr.indexOf(answers);
              this.picked = index;
              this.$nextTick(() => {
                document.querySelectorAll("tr")[index].classList.add("active");
                document
                  .querySelectorAll(".fa")
                  [index].classList.add("fa-check-square");
              });
            }
          })
          .catch(() => {
            localStorage.removeItem(`measureId${this.uid}-${this.measureId}`);
            localStorage.removeItem(`recordTiao${this.uid}-${this.measureId}`);
            this.$message({
              type: "info",
              showClose: true,
              duration: 1500,
              message: "已重置",
            });
            this.brief = false;
          });
      }
    },
    // 下一题
    next() {
      // 当前任务ID
      var id = this.questionsList[this.index].id;
      // 判断能否进入下一题
      if (this.picked === "") {
        this.$message({
          showClose: true,
          duration: 1500,
          message: "选择后，才能进入下一题",
          type: "warning",
        });
        return;
      }
      // 提交新数据处理
      // arr：选项A-Z
      var arr = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ];
      var answer = arr[this.picked]; //this.picked 当前选择选项序列号
      var obj = { id, answer }; //  当前题目答案包装
      // 判断是否已经添加过数据
      var tmp = this.answer.some((v) => {
        if (v.id == id) {
          return true;
        } else {
          return false;
        }
      });
      // 判断是更新还是新增;
      if (tmp) {
        // 更新
        this.answer[this.index] = obj;
      } else {
        // 新增
        this.answer.push(obj);
        localStorage.setItem(
          `measureId${this.uid}-${this.measureId}`,
          JSON.stringify(this.answer)
        );
        // 计算进度条
        this.Progress = Math.ceil(
          (this.answer.length / this.questionsList.length) * 100
        );
      }
      // 判断是否完成
      if (this.index >= this.questionsList.length - 1) {
        this.$message({
          showClose: true,
          duration: 1500,
          message: "已经完成了所有题目,没有问题就可以点击提交了哦",
          type: "warning",
        });
        return;
      }
      // // 字体图标的变换
      var p = document.querySelectorAll(".fa");
      // // 1-还原所有
      p.forEach((v) => {
        v.classList.remove("fa-check-square");
        v.classList.add("fa-square-o");
      });
      var tr = document.querySelectorAll("tr");
      tr.forEach((v) => {
        v.classList.remove("active");
      });
      // --------------------------跳题-start------------------------------------
      // 1.判断是否跳题
      // 2.判断需要跳多少题
      // 3.把跳过的题添加为空的答案
      // 4.记录调后的题目.打上标记方便回跳
      // ++++++++++
      // this.questionsList[this.index])：跳题之前的题目
      // this.picked：选项下标
      let tiao = this.questionsList[this.index].jump_info[this.picked];
      //olIndex：开始添加空题的下标     isIndex：当前题目下标
      let olIndex = JSON.parse(JSON.stringify(this.index)) + 1;
      let isIndex = JSON.parse(JSON.stringify(this.index));
      if (tiao != undefined && tiao > this.index) {
        // 跳题后的下标
        this.index = tiao - 1;
        // 处理已经跳过题的答案 olIndex +1  this.index
        for (olIndex; olIndex < this.index; olIndex++) {
          let obj = {
            id: this.questionsList[olIndex].id,
            answer: "",
          };
          if (tmp) {
            this.answer[olIndex] = obj;
          } else {
            this.answer.push(obj);
          }
        }
        localStorage.setItem(
          `measureId${this.uid}-${this.measureId}`,
          JSON.stringify(this.answer)
        );
        // 计算进度条
        this.Progress = Math.ceil(
          (this.answer.length / this.questionsList.length) * 100
        );
        // 记录跳题后的ID 用于 用于回溯
        let recordTmp = {
          id: this.questionsList[this.index].id,
          from: isIndex,
        };
        this.recordTiao.push(recordTmp);
        localStorage.setItem(
          `recordTiao${this.uid}-${this.measureId}`,
          JSON.stringify(this.recordTiao)
        );
      } else {
        // 页码+1
        this.index++;
      }

      // --------------------------跳题-end--------------------------------------

      // 清空
      this.picked = "";
      // 渲染已选择的题目
      this.$nextTick(() => {
        this.loadnext();
      });
    },
    // 上一题
    last() {
      if (this.index <= 0) {
        this.$message({
          showClose: true,
          duration: 1500,
          message: "已经是第一道题了",
          type: "warning",
        });
        return;
      }
      // 查找跳题记录中是否存在
      let a = this.recordTiao.find((v) => {
        return v.id == this.questionsList[this.index].id;
      });
      if (a != undefined) {
        this.index = a.from;
      } else {
        this.index--;
      }
      this.$nextTick(() => {
        this.loadnext();
      });
    },
    // 渲染已选择的题目
    loadnext() {
      var id = this.questionsList[this.index].id;
      // 先判断是否有值，没有则 退出
      var tmp = this.answer.some((v) => {
        if (v.id == id) {
          return true;
        } else {
          return false;
        }
      });
      if (!tmp) {
        return;
      }
      // 清除样式
      var p = document.querySelectorAll(".fa");
      p.forEach((v) => {
        v.classList.remove("fa-check-square");
        v.classList.add("fa-square-o");
      });
      var tr = document.querySelectorAll("tr");
      tr.forEach((v) => {
        v.classList.remove("active");
      });
      //模拟已选择
      var arr = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ];
      var answers = this.answer[this.index].answer;
      var index = arr.indexOf(answers);
      this.picked = index;
      document.querySelectorAll("tr")[index].classList.add("active");
      document.querySelectorAll(".fa")[index].classList.add("fa-check-square");
    },
    // 提交
    submit() {
      this.isSubmit = true;
      let obj = {
        measureId: this.measureId,
        systemAccess: {
          accessToken: "md5",
        },
        isSave: true,
        answer: this.answer,
      };
      obj.user_id = localStorage.getItem("user_id");
      submit(obj)
        .then((res) => {
          console.log(res);
          let id = res.data.id;
          if (res.code == 400200) {
            console.log(123);
            this.$message({
              message: "恭喜你，提交成功",
              type: "success",
              showClose: true,
              duration: 1500,
            });

            // 判断是否继续填写问卷
            if (this.continueMes) {
              let allMesId = JSON.parse(localStorage.getItem("allMesId"));
              allMesId = allMesId.filter((v) => {
                return v != this.$route.query.id;
              });
              console.log(allMesId, this.$route.query.id);
              z;
              if (allMesId.length == 0) {
                this.$router.push(`/answer_content/answerUserMeasure`);
              } else {
                this.$router.push(`/answer_content?id=${allMesId[0]}`);
                localStorage.setItem("allMesId", JSON.stringify(allMesId));
                this.reload();
              }
            } else {
              if (this.type === 1) {
                this.$router.push(`/answer_content/answerChart?id=${id}`);
              } else {
                this.$router.push(`/answer_content/answer_list`);
              }
            }
            this.isSubmit = false;
            localStorage.removeItem(`measureId${this.uid}-${this.measureId}`);
            localStorage.removeItem(`recordTiao${this.uid}-${this.measureId}`);
          } else {
            this.isSubmit = false;
          }
        })
        .catch((err) => {
          this.isSubmit = false;
        });
    },
  },
};
</script>
<style lang="less">
.tc-answer-content {
  height: 100%;
  .answer-content-layout {
    max-width: 1200px;
    min-width: 320px;
    margin: 0 auto;
    padding-bottom: 10px;
    .title {
      text-align: center;
      font-weight: 700;
      font-size: 28px;
      padding: 20px 0;
    }
    .msg {
      padding: 15px;
    }
    .describe {
      padding: 15px;
    }
    .answer-main {
      height: calc(~"100% - 300px");
      min-height: 500px;
      position: relative;
      box-sizing: border-box;
      width: 100%;
      // height: 100%;
      background: white;
      border-radius: 20px;
      padding: 20px;
      z-index: 1;
      .main-title {
        font-size: 25px;
        border-bottom: 1px #cccccc solid;
        color: rgb(9, 100, 204);
        padding-bottom: 10px;
        img {
          width: 180px;
        }
      }
      .centen {
        padding: 30px 0;
        width: 100%;
        table {
          width: 100%;
          tr {
            width: 100%;
            &:hover {
              background: paleturquoise;
            }
            td {
              padding: 10px 10px;
              width: 100%;
              input[type="radio"] {
                display: none;
              }
              .fa-check-square,
              .fa-square-o {
                font-size: 25px;
                color: rgb(9, 100, 204);
                vertical-align: middle;
              }
              i {
                width: 50px;
              }
              span {
                vertical-align: middle;
                font-size: 20px;
                color: rgb(9, 100, 204);
              }
              label {
                width: 100%;
                display: inline-block;
              }
              img {
                width: 55px;
                vertical-align: middle;
              }
            }
          }
        }
      }
      .active {
        background: paleturquoise;
      }
    }
    .footer {
      position: relative;
      z-index: 999;
      box-sizing: border-box;
      padding: 0 10%;
      width: 100%;
      height: 80px;
      border-radius: 0 0 20px 20px;
      box-shadow: -1px -12px 16px #9c9c9c;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: rgb(255, 255, 255);
      transform: translateY(-10px);
      .btn {
        .el-button {
          font-size: 28px;
        }
      }
      .sum {
        flex: 3;
        text-align: center;
        font-size: 20px;
        color: rgb(96, 98, 102);
        .sum_info {
          color: orange;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .tc-answer-content {
    // 加载等待框
    .el-loading-mask {
      .el-loading-spinner {
        .el-icon-loading {
          color: rgb(34, 77, 85);
        }
        .el-loading-text {
          color: rgb(34, 77, 85);
          font-weight: 700;
        }
      }
    }
    .answer-content-layout {
      max-width: 812px;
      min-width: 320px;
      margin: 0 auto;
      .title {
        text-align: center;
        font-weight: 700;
        font-size: 20px;
        padding: 10px 0;
        .el-progress.el-progress--line {
          padding: 0 20px;
          .el-progress-bar__outer {
            height: 3px !important;
          }
        }
      }
      .answer-main {
        height: calc(~"100% - 150px");
        min-height: calc(100vh - 215px);
        // overflow: auto;
        position: relative;
        box-sizing: border-box;
        width: 100%;
        background: white;
        border-radius: 20px;
        padding: 15px 20px;
        z-index: 1;
        .main-title {
          font-weight: 700;
          font-size: 18px;
          border-bottom: 1px #cccccc solid;
          color: rgb(9, 100, 204);
          padding-bottom: 10px;
        }
        .centen {
          padding: 30px 0;
          width: 100%;
          table {
            width: 100%;
            tr {
              width: 100%;
              &:hover {
                background: transparent;
              }
              &:active {
                background: paleturquoise;
              }

              td {
                padding: 10px 10px;
                width: 100%;
                input[type="radio"] {
                  display: none;
                }
                .fa-check-square,
                .fa-square-o {
                  font-size: 20px;
                  color: rgb(9, 100, 204);
                  vertical-align: middle;
                }
                i {
                  width: 30px;
                }
                span {
                  font-size: 16px;
                  color: rgb(9, 100, 204);
                  vertical-align: middle;
                }
                label {
                  width: 100%;
                  display: inline-block;
                }
              }
            }
          }
        }
        .active {
          // background: paleturquoise;
        }
      }
      .footer {
        position: relative;
        z-index: 999;
        box-sizing: border-box;
        padding: 0 3%;
        width: 100%;
        height: 80px;
        border-radius: 0 0 20px 20px;
        box-shadow: -1px -12px 16px #9c9c9c;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: rgb(255, 255, 255);
        transform: translateY(-10px);
        .btn {
          .el-button {
            font-size: 16px;
          }
        }
        .sum {
          flex: 3;
          text-align: center;
          font-size: 14px;
          color: rgb(96, 98, 102);
          .sum_info {
            color: orange;
          }
        }
      }
    }
  }
}
</style>